<template>
  <div class="UserHome">
    <!-- <div class="wrapperTop">
      <div class="left">
    <div class="showData">
          <h3 class="index_title">统计分析</h3> -->
    <div v-if="isshow" class="textBox">
      <transition name="slide" @click="tonews">
        <p class="text" :key="text.id" >{{text.val}}</p>
      </transition>
    </div>
    <div class="bottomDiv1" style="width: 97%; height: auto">
      <div class="index_title" style="border-bottom: none">统计分析</div>
      <div class="statistics " style="border-bottom: 3px #F0F0F0 solid;">
          <div  class="top1">
            <div>
              <p class="s_title">
                充电站总数
              </p>
              <p class="s_water">{{ FormData.length_station }}</p>
              <!-- <p class="s_water">0</p> -->

            </div>
          </div>
          <div  class="top1">
            <div>
              <p class="s_title">
                空闲设备
              </p>
              <p class="s_water">{{ FormData.length_device }}</p>
            </div>
          </div>
          <div  class="top1">
            <div>
              <p class="s_title">
                使用中设备
              </p>
              <p class="s_water">{{ FormData.file_device }}</p>
            </div>
          </div>
          <div  class="top1">
            <div>
              <router-link to="/mobile_index/user_manage" class="item">
              <p class="s_title">
                异常设备
              </p>
              <p class="s_water">{{ FormData.file_device }}</p>
              <!-- <p class="s_water">0</p> -->

            </router-link>
            </div>
          </div>
      </div>
      <!-- <el-divider /> -->
      <div class="statistics warp">
        <div  class="top1">
        <div >
          <router-link to="/mobile_index/revenue_manage" class="item">
            <p class="s_title">
              利润(元)
            </p>
            <p class="s_water">{{ FormData.profit }}</p>
            <!-- <p class="s_water">0</p> -->
          </router-link>
          </div>
        </div>
          <div >
            <div>
              <p class="s_title">
                订单总金额(元)
              </p>
              <p class="s_water">{{ FormData.sum_amount }}</p>
              <!-- <p class="s_water">0</p> -->

            </div>
          </div>
          <div >
            <div>
              <router-link to="/mobile_index/order_manage" class="item">
              <p class="s_title">
                充值总金额(元)
              </p>
              <p class="s_water">{{ FormData.utility_bills }}</p>
              <!-- <p class="s_water">0</p> -->

            </router-link>
            </div>
          </div>
          <div >
            <div>
              <router-link to="/mobile_index/order_manage" class="item">
              <p class="s_title">
                充电次数
              </p>
              <p class="s_water">{{ FormData.employ }}</p>
              <!-- <p class="s_water">0</p> -->

              </router-link>
            </div>
          </div>
          <div >
            <div>
              <p class="s_title">
                充电时长(分)
              </p>
              <p class="s_water">{{ FormData.cost_time }}</p>
            </div>
          </div>
          <div >
            <div>
              <p class="s_title">
                总电量(度)
              </p>
              <p class="s_water">{{ FormData.electric_quantity }}</p>
            </div>
          </div>
      </div>
    </div>

    <div class="bottomDiv1" style="width: 97%; height: auto">
      <div class="echartsBing">
        <div class="">
          <h3 class="index_title">充电设备使用情况</h3>
          <div ref="bing1"></div>
        </div>
        <!-- <div class="" ref="">
          <h3 class="index_title">各渠道征收额占比</h3>
          <div ref="bing2"></div>
        </div> -->
        <div class="echartszhu">
          <h3 class="index_title">充电设备数量</h3>
          <div ref="zhu"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 标题文字样式 */
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
.UserHome {
  padding: 2px 30px 2px 2px;
}
.textBox {
    background-color: rgb(250, 245, 235);
    width: 100%;
    height: 30px;
    margin: 5px auto;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  .text {
    margin-left: 10px;
    position: absolute;
    bottom: 0;
    color: orange;
    line-height: 30px;
  }
/* 下半部分样式 */
.wrapperBottom {
  /* background-color: #fff; */
  width: 97.7%;
}
/* 数据展示结束 */
.echartsBing {
  margin-top: 0.5rem;
}
.echartsBing > div {
  width: 100%;
  height: 6rem;
  background: #fff;
  position: relative;
}
.echartsBing > div div {
  width: 100%;
  height: 6rem;
  margin-left: 1%;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.6rem;
}
/* 饼状图结束 */
.echartszhu {
  margin-top: 1.5rem;
}
.echartszhu > div {
  width: 100%;
  height: 6rem;
  background: #fff;
  position: relative;
}
.echartszhu > div div {
  width: 100%;
  height: 6rem;
  margin-left: 1%;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.6rem;
}
.bottomDiv {
  background-color: #fff;
  padding: 15px;
  padding-top: 20px;
}
.bottomDiv1 {
  background-color: #fff;
  padding: 20px;
}
.statistics {
  display: flex;
  width: 100%;
  margin: 0.1rem auto;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.warp {
  flex-wrap: wrap;
}

.statistics > .top1 {
  background-image: url("../../assets/img/five.png") !important;
  color: #29a1ec !important;
}
.statistics > div {
  margin: 0.05rem;
  width: 1.9rem;
  height: 2rem;
  background-size: 100% 100%;
  text-align: center;
}
.statistics > div > div {
  margin: 0.5rem 0;
  cursor: pointer;
}
.statistics > div .s_title {
  font-size: 0.35rem;
  cursor: pointer;
  line-height: 0.5rem;
}
.statistics > div .s_water {
  font-size: 0.4rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 0.5rem;
}
.statistics > div:nth-child(1) {
  background-image: url("../../assets/img/six.png");
  color: #ff6060;
}
.statistics > div:nth-child(2) {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa;
}
.statistics > div:nth-child(3) {
  background-image: url("../../assets/img/five.png");
  color: #29a1ec;
}
.statistics > div:nth-child(4) {
  background-image: url("../../assets/img/three.png");
  color: #6e39e8;
}
.statistics > div:nth-child(5) {
  background-image: url("../../assets/img/two.png");
  color: #94bb22;
}
.statistics > div:nth-child(6) {
  background-image: url("../../assets/img/one.png");
  color: #ffa300;
}
.warp > div {
  width: 2.5rem;
  height: 2rem;
}
</style>

<script>
// import { onMounted, reactive, toRefs, onBeforeUnmount, ref } from "vue-demi";
import { onMounted, reactive, toRefs, ref } from "vue-demi";
import { home_data_summary,fault_message  } from "@/request/api";
import * as echarts from "echarts";
// import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
export default {
  name: "UserHome",
  data () {
    return {
      isshow:false,
      textArr: [],
      textArr1: [],
      textArr2: [],
      number: 0,
      FormData:{}
    }
  },
  computed: {
    text () {
      return {
        id: this.number,
        val: `${this.textArr[this.number]} ${this.textArr1[this.number]} ${this.textArr2[this.number]}`,
        // val1: this.textArr1[this.number],
        // val2: this.textArr2[this.number],
      }
    }
  },
  created(){
    this.getList1()
    this.getList()
    
  },
  mounted () {
    this.startMove()
    
  },
  methods: {
    getList(){
      const dataa = {
            username: localStorage.getItem("username")
          }
          home_data_summary(dataa).then((res) => {
            console.log(res,'数据列表111');
            if (res) {
            
              this.FormData.cost_time = parseFloat(res.data.cost_time).toFixed(2)
              this.FormData.sum_amount = parseFloat( res.data.sum_amount).toFixed(2)
              this.FormData.electric_quantity = parseFloat( res.data.electric_quantity).toFixed(2)
              this.FormData.utility_bills = res.data.utility_bills
              this.FormData.employ = res.data.employ
              
              this.FormData.number_of_ser = res.data.number_of_ser
             
              
              this.FormData.profit = res.data.profit
              this.FormData.length_station = res.data.length_station
              this.FormData.length_device = res.data.length_device
              this.FormData.count_device = res.data.count_device
              this.FormData.file_device = res.data.file_device

              // this.FormData.file_free_device = parseFloat( res.data.file_free_device).toFixed(2)
              // this.FormData.free_device = parseFloat( res.data.free_device).toFixed(2)
              // this.FormData.off_line = parseFloat( res.data.off_line).toFixed(2)
            }
            console.log(this.FormData,'this.FormDatadata.FormData***********');
          });
    },
    getList1(){
      const dataa1 = {
        operator_id :sessionStorage.getItem("userID")
      }
      fault_message(dataa1).then((res) => {
          if (res) {
            if (res.code == 200) {
              console.log(res,'eeeeeeeeeee故障消息');
              res.data.forEach((a)=>{
              if(!a.work_type == 0){
                this.isshow = false


              } else {
                this.textArr.push(a.station_name)
                this.textArr1.push(a.device_id)
                this.textArr2.push(a.status)
                this.isshow = true

              }
              
        })
              }else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("数据获取失败");
          }
        });
     
    },
    tonews(){
      
      this.$router.push({path: "/mobile_index/fault_news"})
    },
    startMove () {
      // eslint-disable-next-line
      let timer = setTimeout(() => {
        if (this.number == this.textArr.length-1) {
          this.number = 0;
        } else {
          this.number += 1;
        }
        this.startMove();
      }, 5000); // 滚动不需要停顿则将2000改成动画持续时间
    }
  },
  // components: {
  //   Vue3SeamlessScroll,
  // },
  setup() {
    const data = reactive({
      // FormData:{}
     
    });
    // const getList = () => {
    //       const dataa = {
    //         username: localStorage.getItem("username")
    //       }
    //       home_data_summary(dataa).then((res) => {
    //         if (res) {
    //           data.FormData = res.data
    //           console.log(data.FormData,'data.FormDatadata.FormData');
    //         }
    //       });
    //   };
    
    const echart = echarts;
    onMounted(() => {
      initBing1();
      // getList()
      // initBing2();
      initZhu();
      // getWeather();
      // getDate();
      // getTODOList();
      // getSysmessage();
    });
    // onBeforeUnmount(() => {
    //   clearInterval(timeInserVal);
    // });

    
    // 饼状图1
    const bing1 = ref(null);
    const initBing1 = () => {
      const mapwarp = echart.init(bing1.value);
      const option = {
        tooltip: {
          backgroundColor: "rgba(0,0,0,.5)",
          textStyle: {
            color: "#bbb",
          },
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          position: function (p) {
            //其中p为当前鼠标的位置
            return [p[0] + 10, p[1] - 10];
          },
        },
        // legend: {
        //   orient: "vertical",
        //   right: "20%",
        //   top: "10%",
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   data: ["空闲", "占用", "离线", "异常"],
        //   textStyle: {
        //     color: "#333",
        //     // fontSize: "16",
        //   },
        // },
        series: [
          {
            type: "pie",
            center: ["50%", "55%"],
            radius: ["40%", "80%"],
            label: { show: false },
            labelLine: { show: true },
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
        show: true,
        formatter(param) {
          // correct the percentage
          return param.name + ' (' + param.percent + '%)';
        }
      },
            data: [
              { value: 10, name: "占用" },
              { value: 100, name: "空闲" },
              { value: 15, name: "离线" },
              { value: 5, name: "异常" },
              // { value: 5, name: "其他类" },
            ],
          },
        ],
      };
      mapwarp.setOption(option);
      window.addEventListener("resize", function () {
        mapwarp.resize();
      });
    };
    // 柱状图
    const zhu = ref(null);
    const initZhu = () => {
      const mapwarp = echart.init(zhu.value);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          // orient: "vertical",
          right: "10%",
          top: "10%",
          textStyle: {
            color: "#333",
            // fontSize: "18",
          },
        },
        grid: {
          top: "30%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
           
          ],
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        series: [
          {
            name: "站点数量",
            type: "bar",
            data: [
              10,12,13,15,9
            ],
          },
          {
            name: "设备数量",
            type: "bar",
            data: [
              90,102,101,120,88
            ],
          },
          {
            name: '枪头数量',
            type: 'line',
            data: [
              105,117,116,135,103
            ],
          }
        ],
      };
      mapwarp.setOption(option);
      window.addEventListener("resize", function () {
        mapwarp.resize();
      });
    };
    // 定时器每分钟请求一次
    // const timeInserVal = setInterval(() => {
    //   getDate();
    // }, 60000);
    return {
      ...toRefs(data),
      // getList,
      bing1,
      // bing2,
      zhu,
    };
  },
};
</script>
